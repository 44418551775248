import { render, staticRenderFns } from "./RegisterTool.vue?vue&type=template&id=2b99e275&scoped=true&"
import script from "./RegisterTool.vue?vue&type=script&lang=js&"
export * from "./RegisterTool.vue?vue&type=script&lang=js&"
import style0 from "./RegisterTool.vue?vue&type=style&index=0&id=2b99e275&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b99e275",
  null
  
)

export default component.exports